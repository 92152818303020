<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <div>
          <b-alert
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              {{ $t('Warning') }}
            </h4>
            <div class="alert-body">
              <span>
                ระบบจะนำการตั้งค่าของยูสเซอร์ที่ระบุ ไปใส่ในเมมเบอร์ภายใต้เอเย่นนี้ทั้งหมด
              </span>
            </div>
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form autocomplete="off">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label class="font-weight-bolder">
                    {{ $t('User Name') }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="User Name"
                    rules="required|min:1|max:16"
                  >
                    <b-form-input
                      v-model="formData.username"
                      :state="errors.length > 0 ? false:null"
                      maxlength="16"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="6"
              >
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :disabled="$wait.is('loading-on-save')"
                  @click="onSave"
                >
                  <feather-icon icon="SaveIcon" />
                  {{ $t('Confirm') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      formData: {
        username: '',
      },
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    async onSave() {
      const isValidForm = await this.$refs.simpleRules.validate()
      if (!isValidForm) {
        return
      }

      this.$swal({
        title: `${this.$t('Are you sure')}?`,
        text: this.$t("Confirm Clone Setting? You can't undo this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        timer: 0,
      }).then(result => {
        if (result.isConfirmed) {
          // this.$swal({
          //   icon: 'success',
          //   title: 'Deleted!',
          //   text: 'Your file has been deleted.',
          //   customClass: {
          //     confirmButton: 'btn btn-success',
          //   },
          // })
          this.confirmed()
        }
      })
    },
    async confirmed() {
      const { formData } = this

      this.$wait.start('loading-on-save')
      try {
        const body = formData

        const { data } = await this.$http.post('/member/accounts/clone-setting', body)
        if (data.success) {
          this.$swal({
            icon: 'success',
            title: this.$t('ACCOUNT_SAVE_SUCCESS'),
          })
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>

<style>
.setting-games {
    min-height: 800px;
}
</style>
